import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import queryString from 'query-string';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  Usage,
  VideoData,
  AppContextProps,
  FloorPlanSizeData,
  FloorPlanStateData,
  RoomDimensionsData,
} from 'types';
import { Building, Listing, Room, Shooting, ServiceOfferWrapper, Marker } from 'models';
import { useSession } from 'hooks';
import {
  acceptOrReject3DMarker,
  registerUsage,
  sendVerificationCode,
  verifyPhoneNumber,
  verifyRequest,
  callBrokerAPI,
  stopRinging,
  getListingForWeb,
  updateFloorplanRoom,
  moveFloorplanRoom,
  updateMarkerWithNewOrder,
  copyFloorplanRoom,
  approveMarker,
  rejectMarker,
  getRoomMarkers,
  login,
  checkIfValidEsoftOrderFromId,
} from 'utils';
import { AgoraRTCClient } from 'utils/agora';
import jwt_decode from 'jwt-decode';
import { Howl } from 'howler';
import { getAPIURL, KEYS, ROLES } from 'config/constants';
import { sounds } from 'assets';

const ring = sounds.softRing.default;

export const AppContext = React.createContext({
  listingId: null,
  doesEsoftOrderExist: _.noop,
  setListingId: _.noop,
  getListing: _.noop,
  fetchNewListing: _.noop,
  listing: null,
  newListing: null,
  setListing: _.noop,
  floorPlanZoom: null,
  setFloorPlanZoom: _.noop,
  building: null,
  currentRoom: null,
  setCurrentRoom: _.noop,
  currentMarker: null,
  currentRoomMarkers: [],
  currentFloor: null,
  floorPlanState: null,
  currentMarkerInc: _.noop,
  currentMarkerDec: _.noop,
  getCurrentMarker: _.noop,
  setCurrentMarker: _.noop,
  getRoomDimensions: _.noop,
  setCurrentFloor: _.noop,
  videos: [],
  currentVideo: null,
  setCurrentVideo: _.noop,
  getCurrentVideo: _.noop,
  nextVideo: _.noop,
  prevVideo: _.noop,
  getVideoName: _.noop,
  setInfoPropertyDisplay: _.noop,
  getInfoPropertyDisplay: _.noop,
  setQRCodeDisplay: _.noop,
  callBroker: _.noop,
  showLoginModal: _.noop,
  showConfirmModal: _.noop,
  sentVerificationCode: false,
  isUserVerified: false,
  isCallOngoing: false,
  displayBrokerReconnecting: false,
  isJoinedSession: false,
  setIsJoinedSession: _.noop,
  displayHangupModal: false,
  setDisplayHangupModal: _.noop,
  setSessionId: _.noop,
  sessionId: null,
  stopRinging: _.noop,
  setModalMessage: _.noop,
  setAlert: _.noop,
  showAlert: false,
  alertContent: null,
  startCall: _.noop,
  sendDataToVRServer: _.noop,
  sendVerificationCode: _.noop,
  verifyPhoneNumber: _.noop,
  verifyRequest: _.noop,
  loginModalOpen: false,
  showMessage: false,
  modalMessageType: null,
  confirmCallModalOpen: false,
  closeConfirmCallModal: _.noop,
  closeLoginModal: _.noop,
  getQRCodeDisplay: _.noop,
  adminToken: null,
  setMarkerState: _.noop,
  hasError: false,
  setHasError: _.noop,
} as AppContextProps);

interface Props {
  children: any;
}

let socket: any;
function AppContextProvider(props: Props) {
  const { uuid, session } = useSession();

  const [listingId, updateListingId] = useState<number>(0);
  const [listing, updateListing] = useState<Listing>(null);
  const [newListing, updateNewListing] = useState<Listing>(null);
  const [building, updateBuilding] = useState<Building>();
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  const [adminUserToken, setAdminToken] = useState<string>('');
  const [adminLoginModalOpen, setAdminLoginModalOpen] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<any>({ type: '', message: '' });
  const [displayBrokerReconnecting, setDisplayBrokerReconnecting] =
    useState<boolean>(false);
  const [isJoinedSession, setIsJoinedSession] = useState<boolean>(false);
  const [displayHangupModal, setDisplayHangupModal] = useState<boolean>(false);
  const [modalMessageType, setModalMessageType] = useState<string>('');
  const [confirmCallModalOpen, setConfirmCallModalOpen] = useState<boolean>(false);
  const [sentVerificationCode, setSentVerificationCode] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [resendTimer, setResendTimer] = useState<number>(null);
  const [resendDisabled, setResendDisabled] = useState<boolean>(true);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [isCallOngoing, setCallOngoing] = useState<boolean>(false);
  const [ringtone, setRingtone] = useState(
    new Howl({
      src: ring,
      loop: true,
    }),
  );
  const [currentMarker, updateCurrentMarker] = useState<number>(0);
  const [currentMarkerIndex, updateCurrentMarkerIndex] = useState<number>(0);
  const [currentFloor, updateCurrentFloor] = useState<number>(0);
  const [floorPlanState, updateFloorplanState] = useState<FloorPlanStateData>();
  const [floorPlanZoom, updateFloorplanZoom] = useState<boolean>(false);
  const [currentRoom, updateCurrentRoom] = useState<Room>();
  const [currentMarkerId, updateCurrentMarkerId] = useState<number>(0);
  const [currentRoomMarkers, setCurrentRoomMarkers] = useState<[]>([]);
  const [videos, updateVideos] = useState<VideoData[]>([]);
  const [currentVideo, updateCurrentVideo] = useState<number>(1);
  const [infoPropertyDisplay, updateInfoPropertyDisplay] = useState<boolean>(false);
  const [qrCodeDisplay, updateQRCodeDisplay] = useState<boolean>(false);
  const [hasError, updateHasError] = useState(false);
  const [pixelId, updatePixelId] = useState<number>(0);

  const setModalMessage = (message: string) => {
    setShowMessage(true);
    setModalMessageType(message);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };
  const setAlert = (type: string, message: string) => {
    setShowAlert(true);
    setAlertContent({ type, message });
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const connectVRServer = async (token: string) => {
    // const websocket_url = 'wss://vr.hauslifenetwork.com';
    // const websocket_url = `ws://${LOCAL_IP_ADDRESS}:443`;
    const websocket_url = 'wss://vr-dev.hauslifenetwork.com';

    socket = new WebSocket(websocket_url);
    socket.onopen = () => {
      authenticate(token);
    };
    const handleSocketMessage = (e: { data: any }) => {
      const data = JSON.parse(e.data);
      if (data.type) {
        switch (data.type) {
          case 'user-left':
            AgoraRTCClient.leave();
            setCallOngoing(false);
            setModalMessage('user-left');
            setIsJoinedSession(false);
            break;
          case 'initialized':
            // eslint-disable-next-line no-case-declarations
            const changeMarkerUrl = {
              type: 'change-marker',
              content: {
                marker_id: currentMarkerId,
                listing_id: listingId,
              },
            };
            sendDataToVRServer(changeMarkerUrl);
            break;
          case 'user-join':
            setModalMessage('user-join');
            setIsJoinedSession(true);
            // eslint-disable-next-line no-case-declarations
            const changeMarker = {
              type: 'change-marker',
              content: {
                marker_id: currentMarkerId,
                listing_id: listingId,
              },
            };
            sendDataToVRServer(changeMarker);
            break;
          case 'spectate-user':
            // eslint-disable-next-line no-case-declarations
            const changeMarkerData = {
              type: 'change-marker',
              content: {
                marker_id: currentMarkerId,
                listing_id: listingId,
              },
            };
            sendDataToVRServer(changeMarkerData);
            break;
          case 'user-lost-connection':
            setModalMessage('user-lost-connection');
            setDisplayBrokerReconnecting(true);
            setIsJoinedSession(false);
            setTimeout(() => {
              setDisplayBrokerReconnecting(false);
            }, 10000);
            break;
          default:
            break;
        }
      }
    };

    socket.onmessage = (e: { data: any }) => {
      handleSocketMessage(e);
    };

    socket.onclose = (e) => {
      AgoraRTCClient.leave();
      setCallOngoing(false);
      setIsJoinedSession(false);
    };

    socket.onerror = (err) => {
      AgoraRTCClient.leave();
      setCallOngoing(false);
      setModalMessage('call-broker-error');
      setIsJoinedSession(false);
      socket.close();

      // setTimeout(() => {
      //   this.connectVRServer();
      // }, 1000);
    };

    const authenticate = (_token: string) => {
      const connectionData = {
        content: {
          device: {
            name: 'web',
          },
          token: _token,
          listingId,
          markerId: currentMarkerId,
        },
        type: 'init',
      };
      const payload = JSON.stringify(connectionData);
      socket.send(payload);
      const decoded_token: { id: string } = jwt_decode(_token);

      // Send heartbeat
      setInterval(() => {
        if (socket.readyState === 1) {
          socket.send(
            JSON.stringify({ type: 'ping', content: { user_id: decoded_token.id } }),
          );
        }
      }, 10 * 1000);
    };
  };
  const sendDataToVRServer = (data: any) => {
    const payload = JSON.stringify(data);
    if (socket && socket.readyState === 1) {
      socket.send(payload);
    }
  };

  const getAdminToken = (): string => {
    const params = queryString.parse(window.location.search);
    return params.token as string;
  };

  const getListing = (id: number, esoft_usage?: boolean) => {
    axios
      .get(`${getAPIURL()}/getListingForWeb?listing_id=${id}&esoft_usage=${esoft_usage}`)
      .then((res) => {
        const { data } = res.data;
        const _listing = new Listing(data);
        const _building: Building = new Building(
          populateFloorPlanRooms(_listing.floorplanRooms),
        );
        const event = new CustomEvent('getBrokerInfo', {
          detail: {
            FBPage: _listing.broker.broker_facebook_business_page,
            pixel_id: _listing.broker.pixel_id,
            gtm_id: _listing.broker.gtm_id,
          },
        });
        window.dispatchEvent(event);
        if (_listing.broker.pixel_id) {
          updatePixelId(_listing.broker.pixel_id);
          const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
          };
          ReactPixel.init(_listing.broker.pixel_id, null, options);
        }
        const _videos: Array<VideoData> = [];
        if (_listing.shootings) {
          _listing.shootings.forEach((shooting: Shooting) => {
            shooting.service_offers.forEach((offer: ServiceOfferWrapper) => {
              if (offer.url && offer.url.match(/\.mp4$/gis)) {
                _videos.push({
                  url: offer.url,
                  name: offer.serviceOffer.display_name_fr,
                });
              }
            });
          });
        }

        // _videos.push({
        //   url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        //   name: 'Video de test 1',
        // });
        // _videos.push({
        //   url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   name: 'Video de test 2',
        // });
        // _videos.push({
        //   url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
        //   name: 'Video de test 3',
        // });
        // _videos.push({
        //   url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
        //   name: 'Video de test 4',
        // });

        updateListingId(id);
        updateListing(_listing);
        updateBuilding(_building);
        updateVideos(_videos);
        updateCurrentVideo(0);
        updateCurrentMarker(0);
        updateCurrentMarkerIndex(0);
      })
      .catch(() => {
        updateHasError(true);
      });
  };

  const fetchNewListing = async (listing_id: number) => {
    const res = await getListingForWeb(listing_id);
    return res;
  };
  const currentMarkerUpdate = (value: number) => {
    const _markers = building ? building.markers : [];
    const _sortedRooms = building ? building.sortedRooms : [];

    if (value >= _markers.length) {
      value = 0;
    }

    if (value < 0) {
      value = _markers.length - 1;
    }

    const roomId = _markers[value] ? _markers[value].room : null;
    const room = _sortedRooms.find((m: any) => {
      return m.id === roomId;
    });
    if (!room) return;

    if (!room.markers || room.markers.length === 0) return;

    let markerIndex = 0;
    if (currentRoom !== room) {
      // Next pressed brings us to the first marker in the next picture
      // value > currentMarker will also be true if we click previous on picture 0
      if (value > currentMarker) {
        markerIndex = 0;
      } else {
        // Previous pressed brings us to the last picture marker in the last picture
        markerIndex = room.markers.length - 1;
      }
    } else if (value > currentMarker) {
      markerIndex = currentMarkerIndex + 1;
    } else {
      markerIndex = currentMarkerIndex - 1;
    }

    if (markerIndex < 0) {
      markerIndex = 0;
    }

    updateCurrentMarkerIndex(markerIndex);

    if (room.floor !== currentFloor) {
      const fp = building.generateFloorPlan(room.floor);

      updateCurrentMarker(value);
      updateCurrentRoom(room);
      updateCurrentFloor(room.floor);
      updateFloorplanState(fp);
    } else {
      updateCurrentMarker(value);
      updateCurrentRoom(room);
    }

    if (floorPlanZoom) {
      updateFloorplanZoom(false);
    }

    updateCurrentMarkerId(room.markers[markerIndex].id);

    // Send data to vr server
    sendDataToVRServer({
      type: 'change-marker',
      content: {
        marker_id: room.markers[markerIndex].id,
        listing_id: room.listing_id,
      },
    });
  };

  const setCurrentRoom = (room: Room) => {
    updateCurrentRoom(room);

    const _buildingMarkers = building ? building.markers : [];
    const _roomMarkers = room && room.markers ? room.markers : [];
    if (!_buildingMarkers.length || !_roomMarkers.length) {
      return;
    }

    let index = 0;
    for (let i = 0; i < _buildingMarkers.length; i++) {
      if (_buildingMarkers[i].id === _roomMarkers[0].id) {
        index = i;
        break;
      }
    }

    updateCurrentRoom(room);
    updateCurrentMarkerId(room.markers[0].id);
    updateCurrentMarker(index);

    const data = {
      type: 'change-marker',
      content: {
        marker_id: room.markers[0].id,
        listing_id: room.listing_id,
      },
    };
    sendDataToVRServer(data);
  };

  const populateFloorPlanRooms = (rooms: Room[]) => {
    let sortedRooms = [];

    const floorPlanRooms: Object = {};

    const markers = [];
    // order rooms by marker's order_index
    rooms.sort((a: Room, b: Room) => {
      const aMarkerIndex =
        (a.markers.length && a.markers[0].order_index) ||
        1000; /* chose 1000 as random big number */
      const bMarkerIndex =
        (b.markers.length && b.markers[0].order_index) ||
        1000; /* chose 1000 as random big number */
      return aMarkerIndex - bMarkerIndex;
    });

    sortedRooms = rooms;
    sortedRooms.forEach((r: Room) => {
      r.markers.forEach((m: Marker) => {
        markers.push({
          id: m.id,
          room: r.id,
          floor: r.floor,
          order_index: m.order_index,
          lowres_media_url: m.lowres_media_url,
        });
      });
    });

    rooms.forEach((room: Room) => {
      const { floor, markers: _markers } = room;

      if (!floorPlanRooms.hasOwnProperty(floor)) {
        floorPlanRooms[floor] = {
          rooms: [],
          minIndex: (room.hasValidMarkers() && _markers[0].order_index) || 1000,
        };
      }

      floorPlanRooms[floor].rooms.push(room);
      if (
        room.hasValidMarkers() &&
        _markers[0] &&
        _markers[0].order_index < floorPlanRooms[floor].minIndex
      ) {
        floorPlanRooms[floor].minIndex = _markers[0].order_index;
      }
      room.valideMarker = room.hasValidMarkers();

      room.rejected = !!_markers.find((marker: Marker) => {
        return marker.state === 2;
      });
    });

    return { sortedRooms, floorPlanRooms, markers };
  };

  const setCurrentFloor = (floor?: number) => {
    let _currentFloor = null;

    const buildingFloorPlanRooms = building ? building.floorPlanRooms : null;
    if (!buildingFloorPlanRooms) return null;

    if (floor === undefined || !buildingFloorPlanRooms.hasOwnProperty(floor)) {
      // set floor with minimum order_index
      let min = 1000;
      Object.keys(buildingFloorPlanRooms).forEach((value) => {
        const floorPlanRooms = buildingFloorPlanRooms[value];
        if (floorPlanRooms.minIndex < min) {
          min = floorPlanRooms.minIndex;
          updateCurrentFloor(Number(value));
          _currentFloor = value;
        }
      });
    } else {
      updateCurrentFloor(floor);
      _currentFloor = floor;
    }

    for (let i = 0; i < buildingFloorPlanRooms[_currentFloor].rooms.length; i++) {
      if (buildingFloorPlanRooms[_currentFloor].rooms[i].hasValidMarkers()) {
        setCurrentRoom(buildingFloorPlanRooms[_currentFloor].rooms[i]);
        break;
      }
    }
    return _currentFloor;
  };

  const toFloorplanScale = (value: number, floorPlanSize: FloorPlanSizeData) => {
    if (floorPlanState.floorplanRawHeight > floorPlanState.floorplanRawWidth) {
      return value * (floorPlanSize.height / floorPlanState.floorplanRawHeight);
    }
    return value * (floorPlanSize.width / floorPlanState.floorplanRawWidth);
  };

  const getRoomDimensions = (
    room: any,
    floorPlanSize: FloorPlanSizeData,
  ): RoomDimensionsData => {
    const roomWidth = toFloorplanScale(room.width * 20, floorPlanSize);
    const roomHeight = toFloorplanScale(room.height * 20, floorPlanSize);
    const leftMargin =
      toFloorplanScale(room.x + floorPlanState.offset_x, floorPlanSize) +
      (floorPlanSize.width -
        toFloorplanScale(floorPlanState.floorplanRawWidth, floorPlanSize)) /
        2;
    const topMargin =
      toFloorplanScale(room.y + floorPlanState.offset_y, floorPlanSize) +
      (floorPlanSize.height -
        toFloorplanScale(floorPlanState.floorplanRawHeight, floorPlanSize)) /
        2;

    return {
      roomWidth,
      roomHeight,
      leftMargin,
      topMargin,
    };
  };

  const updateMarkerState = async (isAccepted: boolean) => {
    const adminToken = getAdminToken();
    if (!adminToken || !building) return;

    let markerId = null;
    if (currentRoom && currentRoom.markers && currentRoom.markers[currentMarker]) {
      markerId = currentRoom.markers[currentMarker].id;
    }
    if (!markerId) return;

    const { floorPlanRooms } = building;
    if (
      !floorPlanRooms ||
      !floorPlanRooms[currentFloor] ||
      !floorPlanRooms[currentFloor].rooms
    ) {
      return;
    }

    const result = await acceptOrReject3DMarker(markerId, isAccepted, adminToken);
    if (result) {
      const updatedState = isAccepted ? 1 : 2;

      // mutate state of current marker in floorplanRooms object
      const { rooms } = floorPlanRooms[currentFloor];
      const roomIndex = rooms.findIndex((room: Room) => {
        return room.id === currentRoom.id;
      });

      rooms[roomIndex].markers[currentMarker].state = updatedState;

      currentMarkerUpdate(currentMarker + 1);
    }
  };

  useEffect(() => {
    if (building) {
      const _currentFloor = setCurrentFloor();
      const _floorplanState = building.generateFloorPlan(_currentFloor);
      updateFloorplanState(_floorplanState);
    }
  }, [building]);

  useEffect(() => {
    const adminToken = getAdminToken();
    const token = reactLocalStorage.get(KEYS.TOKEN, undefined);
    const admin_token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, undefined);
    if (admin_token) {
      setAdminToken(admin_token);
    }
    if (token) {
      setIsUserVerified(true);
    }
    let valid = true;
    valid = adminToken == null && uuid !== null && session !== null;
    if (
      typeof currentRoom === 'undefined' ||
      typeof currentRoom.markers[currentMarkerIndex] === 'undefined'
    ) {
      return;
    }

    if (!valid) return;

    const data: Usage = {
      listing_id: currentRoom.listing_id,
      room_id: currentRoom.id,
      marker_id: currentRoom.markers[currentMarkerIndex].id,
      uuid,
      session,
    };
    const infoText = listing
      ? `${(listing.app ? `${listing.app}-` : '') + listing.address}, ${listing.city}`
      : null;
    if (pixelId) {
      ReactPixel.track('ViewContent', {
        content_name: infoText,
        value: listing.id,
      });
    }
    if (!admin_token) {
      registerUsage(data);
    }
  }, [listing, currentRoom, currentMarkerIndex, uuid, session]);

  const contextData = {
    listingId,
    doesEsoftOrderExist: async (esoft_order_id: number, listing_id: number) => {
      const exist = await checkIfValidEsoftOrderFromId(esoft_order_id, listing_id);
      return exist;
    },
    setListingId: (id: number, esoft_usage: boolean) => {
      if (!listing) {
        getListing(id, esoft_usage);
      }
    },
    listing,
    setListing: updateListing,
    getListing,
    newListing,
    adminUserToken,
    fetchNewListing,
    floorPlanZoom,
    setFloorPlanZoom: (value: boolean) => {
      return updateFloorplanZoom(value);
    },
    building,
    currentRoom,
    updateCurrentRoom,
    setCurrentRoom,
    currentMarker,
    currentFloor,
    currentRoomMarkers,
    floorPlanState,
    sentVerificationCode,
    resendTimer,
    resendDisabled,
    setResendDisabled,
    isUserVerified,
    isCallOngoing,
    setCallOngoing,
    ringtone,
    isJoinedSession,
    setIsJoinedSession,
    displayBrokerReconnecting,
    setDisplayHangupModal,
    displayHangupModal,
    currentMarkerDec: () => {
      updateInfoPropertyDisplay(false);
      updateQRCodeDisplay(false);
      currentMarkerUpdate(currentMarker - 1);
    },
    currentMarkerInc: () => {
      updateInfoPropertyDisplay(false);
      updateQRCodeDisplay(false);
      currentMarkerUpdate(currentMarker + 1);
    },
    getCurrentMarker: () => {
      if (!building) {
        return '';
      }
      return building.markers[currentMarker].lowres_media_url;
    },
    setCurrentMarker: updateCurrentMarker,
    getRoomDimensions,
    setCurrentFloor: (floor: number) => {
      setCurrentFloor(floor);
      if (building) {
        updateFloorplanState(building.generateFloorPlan(floor));
      }
    },
    updateFloorplanState,
    videos,
    currentVideo,
    setCurrentVideo: updateCurrentVideo,
    getCurrentVideo: (): VideoData => {
      if (videos.length && videos.length > currentVideo) {
        return videos[currentVideo];
      }
      return { url: '', name: '' };
    },
    nextVideo: () => {
      if (videos.length <= currentVideo + 1) {
        return;
      }
      updateCurrentVideo(currentVideo + 1);
    },
    prevVideo: () => {
      if (currentVideo === 0) return;
      updateCurrentVideo(currentVideo - 1);
    },
    getVideoName: (index?: number) => {
      if (!index) {
        index = currentVideo;
      }
      if (videos.length <= index) {
        return `${index} ${videos.length}`;
      }
      return videos[index].name;
    },

    setInfoPropertyDisplay: (value?: boolean) => {
      if (value === undefined) {
        value = !infoPropertyDisplay;
      }
      updateInfoPropertyDisplay(value);
    },

    getInfoPropertyDisplay: () => {
      return infoPropertyDisplay;
    },
    setQRCodeDisplay: (value?: boolean) => {
      if (value === undefined) {
        value = !qrCodeDisplay;
      }
      updateQRCodeDisplay(value);
    },
    getQRCodeDisplay: () => {
      return qrCodeDisplay;
    },
    adminToken: getAdminToken(),
    setMarkerState: (isAccepted: boolean) => {
      updateMarkerState(isAccepted);
    },
    stopRinging: async (broker_id: number, session_id: string) => {
      stopRinging(broker_id, session_id);
    },
    callBroker: (session_id: string) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const token = reactLocalStorage.get(KEYS.TOKEN, null);

          await connectVRServer(token);

          const agora_token = await callBrokerAPI(
            listing.id,
            listing.broker.id,
            session_id,
            token,
          );

          resolve(agora_token);
        } catch (error) {
          setConfirmCallModalOpen(false);
          setModalMessage('call-broker-error');
          reject(error);
        }
      });
    },
    startCall: () => {
      setCallOngoing(true);
    },
    sendDataToVRServer,
    loginModalOpen,
    adminLoginModalOpen,
    showMessage,
    modalMessageType,
    confirmCallModalOpen,
    setModalMessage,
    setAlert,
    showAlert,
    alertContent,
    sessionId,
    setSessionId,
    showLoginModal: () => {
      setLoginModalOpen(true);
    },
    showAdminLoginModal: () => {
      setAdminLoginModalOpen(true);
    },
    closeAdminLoginModal: () => {
      setAdminLoginModalOpen(false);
    },
    showConfirmModal: () => {
      setConfirmCallModalOpen(true);
    },
    closeLoginModal: () => {
      setLoginModalOpen(false);
    },
    closeConfirmCallModal: () => {
      setConfirmCallModalOpen(false);
    },
    sendVerificationCode: async (
      firstname: string,
      lastname: string,
      phone_number: string,
      email: string,
    ) => {
      const res = await sendVerificationCode(firstname, lastname, phone_number, email);
      if (res.token) {
        setSentVerificationCode(true);
        reactLocalStorage.set(KEYS.TOKEN, res.token);
        setIsUserVerified(true);
        setLoginModalOpen(false);
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setConfirmCallModalOpen(true);
        } catch (error) {
          setModalMessage('init-error');
        }
      } else {
        if (res.status === 400) {
          setResendDisabled(true);
          const countdown = Number(res.message.match(/\d+/g)[0]);
          setResendTimer(countdown);
        }
        setIsUserVerified(false);
        setSentVerificationCode(false);
      }
    },
    verifyPhoneNumber: async (phone_number: string, verification_code: string) => {
      const res = await verifyPhoneNumber(phone_number, verification_code);
      if (res.status === 200) {
        setIsUserVerified(true);
        reactLocalStorage.set(KEYS.TOKEN, res.token);
        setLoginModalOpen(false);
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setConfirmCallModalOpen(true);
        } catch (error) {
          setModalMessage('init-error');
        }
      } else {
        setIsUserVerified(false);
      }
    },
    verifyRequest: async (
      firstname: string,
      lastname: string,
      phone_number: string,
      email: string,
    ) => {
      const res = await verifyRequest(firstname, lastname, phone_number, email);
      if (res.status === 200) {
        setIsUserVerified(true);
        reactLocalStorage.set(KEYS.TOKEN, res.token);
        setLoginModalOpen(false);
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setConfirmCallModalOpen(true);
        } catch (error) {
          setModalMessage('init-error');
        }
      } else {
        setIsUserVerified(false);
      }
    },
    hasError,
    setHasError: updateHasError,
    updateFloorplanRoom: async (_currentRoom: Room) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await updateFloorplanRoom(token, _currentRoom);
      if (res.status === 200) {
        setAlert('success', 'Saved successfully!');
      } else {
        setAlert('error', 'Failed to save changes!');
      }
      return res;
    },
    moveFloorplanRoom: async (_currentRoom: Room, keepRoom: boolean) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await moveFloorplanRoom(token, _currentRoom, keepRoom);
      if (res.status === 200) {
        setAlert('success', 'Moved successfully!');
      } else {
        setAlert('error', 'Failed to save changes!');
      }
      return res;
    },
    updateMarkerOrder: async (markers) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await updateMarkerWithNewOrder(token, markers);
      if (res.status === 200) {
        setAlert('success', 'Saved order successfully!');
      } else {
        setAlert('error', 'Failed to save changes!');
      }
      return res;
    },
    copyFloorplanRoom: async (
      listing_id: number,
      markers_to_copy: [],
      room_id: number,
    ) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await copyFloorplanRoom(token, listing_id, markers_to_copy, room_id);
      if (res.status === 200) {
        setAlert('success', 'Saved order successfully!');
      } else {
        setAlert('error', 'Failed to save changes!');
      }
      return res;
    },
    approveMarker: async (listing_id: number, marker_id: number) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await approveMarker(token, listing_id, marker_id);
      if (res.status === 200) {
        setAlert('success', 'Approved marker!');
      } else {
        setAlert('error', 'Failed to approve marker!');
      }
      return res;
    },
    rejectMarker: async (marker_id: number) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await rejectMarker(token, marker_id);
      if (res.status === 200) {
        setAlert('success', 'Rejected marker!');
      } else {
        setAlert('error', 'Failed to reject marker!');
      }
      return res;
    },
    getRoomMarkers: async (room_id: number) => {
      const token = reactLocalStorage.get(KEYS.ADMIN_TOKEN, null);
      const res = await getRoomMarkers(token, room_id);
      if (res.status === 200) {
        const markers = res.data;
        setCurrentRoomMarkers(markers);
      } else {
        setAlert('error', 'Failed to get room markers!');
      }
      return res;
    },
    login: async (email: string, password: string) => {
      const res = await login(email, password);
      const authorizedRoles = [
        ROLES.SUPER_ADMIN,
        ROLES.ADMIN,
        ROLES.TECH_CAPTURE,
        ROLES.TECH_POSTPROD,
        ROLES.TECH_INJECTOR,
        ROLES.TECH_POSTPROD_LEAD,
      ];
      if (res && res.status === 200 && authorizedRoles.includes(res.user.role)) {
        reactLocalStorage.set(KEYS.ADMIN_TOKEN, res.token);
        reactLocalStorage.set(KEYS.LANGUAGE, res.user.language);
        setAdminToken(res.token);
      }
      return res;
    },
  };

  const { children } = props;

  return <AppContext.Provider value={contextData}>{children}</AppContext.Provider>;
}

AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppContextProvider;
